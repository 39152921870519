import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";
import me from "../assets/me.jpg";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    const one = (
      <p>
        I am currently a Software Engineer at
        <a
          href="https://www.essvote.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Election Systems & Software
        </a>
        , developing secure software solutions for election management. I am
        also pursuing a part-time Master's of Science in Computer Science,
        focusing on scientific computing, at
        <a
          href="https://www.harrisburgu.edu/programs/ms-computer-information-sciences/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Harrisburg University.
        </a>
      </p>
    );
    const two = (
      <p>
        Outside of work, you can find me on the soccer field, playing video
        games, or glued to a good action movie (with the occasional romcom for
        my sensitive side)
      </p>
    );
    // const three = (
    //   <p>
    //     <b>Want to chat?</b> Shoot me a message at{" "}
    //     <a href="mailto:sergefrancis6@gmail.com">
    //       sergefrancis6@gmail.com
    //     </a>{" "}
    //     and let's talk.
    //   </p>
    // );

    const tech_stack = [
      "TypeScript",
      "Javascript ES6+",
      "Python",
      "C#",
      "Angular",
      "React",
      "Java",
      "Node.js",
    ];

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ about me</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {[one]}
              {"Here are some technologies I have been working with:"}
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li>{tech_item}</li>
                    </FadeInSection>
                  );
                })}
              </ul>
              {[two]}
            </div>
            <div className="about-image">
              <img src={me} alt="Description here" />
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;

import React from "react";
import FadeInSection from "./FadeInSection";
import SchoolIcon from "@mui/icons-material/School";
import ApartmentIcon from "@mui/icons-material/Apartment";
import "../styles/Education.css";

class Education extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }

  render() {
    return (
      <div id="education">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ education </span>
          </div>
          <div className="section-content">
            <div className="school_name">
              <SchoolIcon fontSize="medium"></SchoolIcon>
              MS Computer Science - Scientific Computing
            </div>
            <div className="school_details">
              <ApartmentIcon></ApartmentIcon>
              <a
                href="https://www.harrisburgu.edu/programs/ms-computer-information-sciences/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Harrisburg University of Science and Technology
              </a>
              {"Expected 2026"}
            </div>
          </div>
          <div className="education-spacer"></div>
          <div className="section-content">
            <div className="school_name">
              <SchoolIcon fontSize="medium"></SchoolIcon>
              BS. Integrated Sciences, Computer Science
            </div>
            <div className="school_details">
              <ApartmentIcon></ApartmentIcon>
              <a
                href="https://www.unl.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                University Of Nebraska-Lincoln
              </a>
              {"2019-2023"}
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default Education;

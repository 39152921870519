import React from "react";
import resume from "../assets/resume.pdf";
import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";
// import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    return (
      <div id="intro">
        <Typist avgTypingDelay={120}>
          <span className="intro-title">
            {"hi, "}
            <span className="intro-name">{"francis"}</span>
            {" here!"}
          </span>
        </Typist>
        <FadeInSection>
          <div className="intro-subtitle">
            I develop stuff that make people's lives simple.
          </div>
          <div className="intro-desc">
            I'm a software engineer based in Pittsburgh (but free to move and
            explore!). I have a strong passion for full-stack development and
            exploring the intersection of artificial intelligence and
            human-computer interactions.
          </div>
          <a href="mailto:sergefrancis6@gmail.com" className="intro-contact">
            <EmailRoundedIcon></EmailRoundedIcon>
            {"  Say hi !"}
          </a>
          <a
            href={resume}
            target="_blank"
            rel="noopener noreferrer"
            className="download-resume"
          >
            <OpenInNewIcon></OpenInNewIcon>
            {"  Resume"}
          </a>
        </FadeInSection>
      </div>
    );
  }
}

export default Intro;

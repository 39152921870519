import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ExternalLinks from "./ExternalLinks";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    const projects = {
      "Maze A.I": {
        desc:
          "A self-learning A.I. that learns to traverse through a maze by the genetic algorithm.",
        techStack: "JavaScript, HTML/CSS, Genetic algorithm",
        link: "https://github.com/Francis220/Maze-AI",
        open: "https://francis220.github.io/Maze-AI/",
      },

      "Cryptocurrency Trading Bot": {
        desc:
          "A trading bot to automate cryptocurrency trading strategies using Python, equipped with a basic GUI.",
        techStack: "Python",
        link: "https://github.com/Francis220/Crypto",
      },

      "New Grad Job Board": {
        desc:
          "A website that posts computer science job opportunities for new graduates, drawing in more than 1,000 visitors every week.",
        techStack: "TypeScript, MongoDb",
        open: "https://thenewgrad.com/",
      },

      Uniwise: {
        desc:
          "A full-stack website that hosts guides and information to help international students adjust to living and studying in the US.",
        techStack: "TypeScript, React.js, Node.js, MongoDB, JWT",
        link: "https://uniwise.netlify.app/",
        open: "https://uniwise.netlify.app/",
      },

      "Asset Management System": {
        desc:
          "A Java program that implements an SQL database-backed asset management system to handle a large list of clienteles and their assets",
        techStack: "Java, SQL (MariaDB)",
        link: "https://github.com/Francis220/Asset-Management-System",
      },
      "Movie Database": {
        desc: "An interactive Movie Database app using ReactJS.",
        techStack: "JavaScript React, CSS, HTML",
        link: "https://github.com/Francis220/Movie-Database",
      },
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ fun projects</span>
        </div>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <ExternalLinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></ExternalLinks>
                  </div>
                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;

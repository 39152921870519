import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  } else {
    return {
      id: `vertical-tab-${index}`,
    };
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "Election Systems & Software": {
      jobTitle: "Software Engineer @",
      subjobTitle: "Software Engineering Intern (Jun 2023 - Feb 2024)",
      duration: "Feb 2024 - Present",
      desc: [
        "Developed and enhanced key features in a smart card provisioning system using Angular and C#, focusing on intuitive user navigation and robust multi-level access controls",
        "Participated in scrum ceremonies practicing agile software development strategies including test driven development and constant integration",
      ],
    },

    Nelnet: {
      jobTitle: "Software Developer @",
      duration: "Aug 2022 - May 2023 (Senior Capstone Project)",
      desc: [
        "Developed an Angular and Node.js web application to house loan data for over 2000 internal users, ensuring secure storage and retrieval of sensitive informatio",
        "Developed a Java-based security monitoring system to proactively detect and alert administrators of any unauthorized access or potential information leaks. The system was able to notify administrators within 7 minutes of any suspicious activity, greatly improving the security of the application.",
      ],
    },

    "Compass North": {
      jobTitle: "Software Engineering Intern @",
      duration: "May 2022 - Aug 2022",
      desc: [
        "Implemented a user-friendly interface for private jet pilots and dispatchers, providing real-time access to flight information and streamlining processes. The new interface reduced the time spent on these tasks by 2%, improving efficiency and productivity. ",
        "Compiled and maintained a comprehensive library of technical documentation consisting of over 20 pages, which helped improve the functionality of the platform. This library served as a valuable resource for our team, providing detailed information on how it was built. Regularly updated the documentation to ensure accuracy and relevance.",
      ],
    },

    USDA: {
      jobTitle: "Software Development Intern @",
      duration: "Sep 2020 - Feb 2022",
      desc: [
        "Led the redesign and implementation of the USDA's search results page, utilizing a new design system to improve user engagement and satisfaction. The redesigned page resulted in an increase of 3% or more in user engagement. Utilized Angular, Python, and C# to implement the new design and conducted user testing to ensure its effectiveness.",
        "Authored over 900 codemods to upgrade legacy React components to strict mode-compliant versions, significantly improving the performance and reliability of the website.",
      ],
    },

    "Frontier Coop": {
      jobTitle: "Software Engineering Intern @",
      duration: "May 2020 - Aug 2020",
      desc: [
        "Collaborated with a team of 4 developers to design and implement back-end features and functionality for a web app that processed 10,000 data points daily, enabling accurate prediction of agricultural yields. Utilized Java, Python, and AWS to develop the app and worked closely with the development team to ensure smooth integration with front-end components.",
        "Guaranteed satisfaction of 500+ clients by providing technical support via calls, chats, and social media engagement.",
      ],
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <div className="joblist-subjobTitle">
            {experienceItems[key]["subjobTitle"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
